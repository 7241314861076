import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ReactComponent as ShareIcon } from '../Assets/share-solid.svg';
import { ReactComponent as SearchIcon } from '../Assets/magnifying-glass-solid.svg';
import { signInWithEmailLink, isSignInWithEmailLink, signInWithEmailAndPassword } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject, uploadBytes } from "firebase/storage";
import { collection, getDocs, getDoc, query, where, updateDoc, doc, arrayUnion, addDoc, deleteDoc, setDoc, arrayRemove } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { db, auth } from '../firebase';
import './Home.css';
import './Landing.css';
import Logo from '../Assets/wai-logo-white.png';
import LogoBlack from '../Assets/wai-logo-black.png';
import { ReactComponent as InstagramIcon } from '../Assets/instagram.svg';
import { ReactComponent as TwitterIcon } from '../Assets/x-twitter.svg';
import { ReactComponent as LinkedInIcon } from '../Assets/linkedin-in.svg';
import { ReactComponent as TikTokIcon } from '../Assets/tiktok.svg';
import SettingsIcon from '../Assets/search-settings-icon.svg';
import '../spinner.css';
import { getAnalytics, logEvent } from "firebase/analytics";
import axios from 'axios';
import ConfettiExplosion from 'react-confetti-explosion';


function Landing() {
    const navigate = useNavigate();
    const analytics = getAnalytics();
    const [menuOpen, setMenuOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState(null);
    const [refCode, setRefCode] = useState(null);
    const [signinModal, setSigninModal] = useState(false);
    const [signupModal, setSignupModal] = useState(false);
    const [foundError, setFoundError] = useState(false);
    const [error, setError] = useState('');    
    const functions = getFunctions();
    const [emailResult, setEmailResult] = useState('');
    const searchSignup = httpsCallable(functions, 'signup');
    const [feedbackAsk, setFeedbackAsk] = useState(false);
    const [waitlistModal, setWaitlistModal] = useState(false);
    const [email, setEmail] = useState('');
    const [userCountry, setUserCountry] = useState('');
    const [userWaitlistPosition, setUserWaitlistPosition] = useState(0);
    const [isWaitlistPositionLoading, setIsWaitlistPositionLoading] = useState(false);
    const getNewItemsSuggestions = httpsCallable(functions, 'new_items_suggestion');
    const getTrendingBrand = httpsCallable(functions, 'get_trending_brands');
    const [suggestedStyles, setSuggestedStyles] = useState(["streetwear", "casual", "vintage", "formal", "retro", 'beachwear', 'avantgarde', 'businesscasual', 'sporty']);
    const [communityPosts, setCommunityPosts] = useState(["pilcha-event-tickets"]);
    const [recomendedItems, setRecomendedItems] = useState([]);
    const [trendingBrands, setTrendingBrands] = useState([]);
    const [isPilcha, setIsPilcha] = useState(false);
    const [pilchaQueryId, setPilchaQueryId] = useState(null);
    const [newRecomendedItems, setNewRecomendedItems] = useState([]);
    const occasions_examples_list = [
        "Tengo una joda en la playa.",
        "Vestime como Taylor Swift.",
        "Reunion en la oficina.",
        "https://pinterest.com/pin/...",
        "Yendo a un casamiento.",
        "Quiero verme como la Rosalia",
        "Un día de museo.",
        "Dame el estilo de CRO.",
        'https://instagram.com/p/...',
        "https://pinterest.com/pin/...",
        "Vamos a un boliche.",
        "Noche de cine.",
        "Picnic de verano en la plaza.",
        "https://pinterest.com/pin/...",
        "Volley en la playa.",
        "Mañana de GYM.",
        'https://instagram.com/p/...',
        "Festejo de cumpleaños.",
        "https://pinterest.com/pin/...",
        "Brunch de domingo con la flia.",
        "Me voy a hacer ski a la montaña.",
        "Me voy al fashionweek.",
    ];

    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const [typingIndex, setTypingIndex] = useState(0);
    const [placeholder, setPlaceholder] = useState('');

    useEffect(() => {
        const fetchData = async () => {
        if (!auth.currentUser) {
          if (isSignInWithEmailLink(auth, window.location.href)) {
            if (!auth.currentUser) {
              let email = window.localStorage.getItem('emailForSignIn');
              if (!email) {
                email = window.prompt('Porfavor ingresa tu email para confirmacion');
              }
              try {
                const result = await signInWithEmailLink(auth, email, window.location.href);
                window.localStorage.removeItem('emailForSignIn');
                if (result.additionalUserInfo.isNewUser) {
                    <ConfettiExplosion />
                }
                window.history.replaceState({}, document.title, window.location.href.split('?')[0]);

              } catch (error) {
                console.log(error.message);
                setFoundError(true);
                setError(error.message);
              }
            }
          }
        }
        };
        
        fetchData();
      }, []);

      useEffect(() => {
        const fetchData = async () => {
            const cachedNewRecomendedItems = sessionStorage.getItem('newRecomendedItems');
            const cachedTrendingBrands = sessionStorage.getItem('trendingBrands');
            if (cachedNewRecomendedItems) {
                const parsedItems = JSON.parse(cachedNewRecomendedItems);
                const validItems = parsedItems.filter(item => item.item_image !== null && item.item_image !== "");
                setNewRecomendedItems(validItems);
            } else {
                const resultNew = await getNewItemsSuggestions();
                const dataNew = resultNew.data.filter(item => item.item_image !== null && item.item_image !== "");
                setNewRecomendedItems(dataNew);
                console.log('New Item Suggestions:', dataNew);
                sessionStorage.setItem('newRecomendedItems', JSON.stringify(dataNew));
            }
            if (cachedTrendingBrands) {
                setTrendingBrands(JSON.parse(cachedTrendingBrands));
            } else {
                const resultTrending = await getTrendingBrand();
                const dataTrending = resultTrending.data;
                setTrendingBrands(dataTrending);
                console.log('Trending Brands:', resultTrending.data);
                sessionStorage.setItem('trendingBrands', JSON.stringify(dataTrending));
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const container = document.querySelector('.new-recomended-items-container-items');
        if (container) {
            const itemCount = newRecomendedItems.filter(item => item.item_image !== null && item.item_image !== "").length;
            const rowCount = itemCount <= 10 ? 1 : 2;
            container.style.gridTemplateRows = `repeat(${rowCount}, 1fr)`;
        }
    }, [newRecomendedItems]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sharedCode = urlParams.get('invite');
        const isPilcha = urlParams.get('pilcha');
        if (sharedCode) {
            setCode(sharedCode);
        }
        if (isPilcha) {
            setIsPilcha(true);
            setWaitlistModal(true);
            setPilchaQueryId(isPilcha);
        }
        const refCode = urlParams.get('ref');
        if (refCode) {
            setRefCode(refCode);
        }
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const welcomeBackEmail = urlParams.get('back_email');
        if (welcomeBackEmail) {
            setEmail(welcomeBackEmail);
            setLoading(true);

            // Removed nested useEffect and directly called addToWaitlist if userCountry is set
            if (userCountry) {
                addToWaitlist(welcomeBackEmail);
            }
        }
    }, [userCountry]);


    useEffect(() => {
        if (signinModal) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [signinModal]);

    useEffect(() => {
        const fetchCountry = async () => {
            const result = await axios.get('https://ipapi.co/json/');
            if (result.status == 200) {
                const country = result.data.country_name;
                setUserCountry(country);
            }
        };
        fetchCountry();
    }, []);

    useEffect(() => {
        let lastRandomIndex = -1;
        const changePlaceholderIntervalId = setInterval(() => {
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * occasions_examples_list.length);
            } while (randomIndex === lastRandomIndex);
            lastRandomIndex = randomIndex;
            setPlaceholderIndex(randomIndex);
            setTypingIndex(0); // Reset typing index for new phrase
        }, 5000); // Change phrase every 3 seconds TODO: CHange to make it adaptable to phrase length
    
        return () => clearInterval(changePlaceholderIntervalId);
    }, []);
    
    useEffect(() => {
        let typewriterIntervalId;
        if (placeholderIndex !== null) {
            typewriterIntervalId = setInterval(() => {
                setTypingIndex((prevTypingIndex) => {
                    const phrase = occasions_examples_list[placeholderIndex];
                    if (prevTypingIndex < phrase.length) {
                        setPlaceholder(phrase.slice(0, prevTypingIndex + 1));
                        return prevTypingIndex + 1;
                    } else {
                        clearInterval(typewriterIntervalId);
                        return prevTypingIndex;
                    }
                });
            }, 150);
        }
    
        return () => clearInterval(typewriterIntervalId);
    }, [placeholderIndex]);

    const addToWaitlist = async (email) => {
        if (email) {
            setIsWaitlistPositionLoading(true); // Start loading
            searchSignup({ email: email, code: code, country: userCountry, refCode: refCode, pilchaQueryId: pilchaQueryId || '' })
            .then((result) => {
                setLoading(false);
                const data = result.data;
                if (data.code === 'login_link') {
                    setEmailResult('Bienvenido devuelta! Te enviamos un link a tu email.');
                    window.localStorage.setItem('emailForSignIn', email);
                    logEvent(analytics, data.code);
                } else if (data.code == 'waitlisted') {
                    setFeedbackAsk(true);
                    setEmailResult('Gracias! Estas en la lista de espera.');
                    setUserWaitlistPosition(data.waitlist_position); // Update waitlist position
                    logEvent(analytics, data.code);
                } else if (data.code == 'login_link_w_code') {
                    setEmailResult('Invitacion aceptada. Te enviamos un link a tu email!');
                    window.localStorage.setItem('emailForSignIn', email);
                    logEvent(analytics, data.code);
                } else if (data.code == 'waitlisted_w_code') {
                    setEmailResult('El codigo ha sido usado anteriormente, ahora estas en la lista de espera.');
                    setUserWaitlistPosition(data.waitlist_position); // Update waitlist position
                    setFeedbackAsk(true);
                    logEvent(analytics, "waitlisted");
                }
                setIsWaitlistPositionLoading(false); // Stop loading
            })
            .catch((error) => {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log('Error:', code + message + details);
                setLoading(false);
                setFoundError(true);
                setError(error);
                setIsWaitlistPositionLoading(false); // Stop loading
            });
        }
    }
        

    const handleRefShare = (email) => {
        const shareUrl = `https://wearlyapp.com?ref=${email}`
        navigator.clipboard.writeText(shareUrl).then(() => {
            
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }

    const handleFeedback = (e) => {
        setFeedbackAsk(false);
        e.preventDefault();
        if (!email) {
            return;
        }
        const brand = e.target[0].value;
        const reason = e.target[1].value;
        const feedback = { brand: brand, reason: reason, email: email};
        addDoc(collection(db, 'feedback'), feedback)
        .then((result) => {
            console.log(result);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const handleEmail = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        const email = e.target[0].value;
        setEmail(email);
        setLoading(true);
        setError('');
        setFoundError(false);
        setEmailResult('');
        if (email) {
            searchSignup({ email: email, code: code, country: userCountry, refCode: refCode, pilchaQueryId: pilchaQueryId || '' })
            .then((result) => {
                const data = result.data;
                if (data.code === 'login_link') {
                    setEmailResult('Bienvenido devuelta! Te enviamos un link a tu email.');
                    window.localStorage.setItem('emailForSignIn', email);
                    logEvent(analytics, data.code);
                } else if (data.code == 'waitlisted') {
                    setFeedbackAsk(true);
                    setEmailResult('Gracias! Estas en la lista de espera.');
                    setUserWaitlistPosition(data.waitlist_position); // Update waitlist position
                    logEvent(analytics, data.code);
                } else if (data.code == 'login_link_w_code') {
                    setEmailResult('Invitacion aceptada. Te enviamos un link a tu email!');
                    window.localStorage.setItem('emailForSignIn', email);
                    logEvent(analytics, data.code);
                } else if (data.code == 'waitlisted_w_code') {
                    setEmailResult('El codigo ha sido usado anteriormente, ahora estas en la lista de espera.');
                    setUserWaitlistPosition(data.waitlist_position); // Update waitlist position
                    setFeedbackAsk(true);
                    logEvent(analytics, "waitlisted");
                }
                setLoading(false);
            })
            .catch((error) => {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log('Error:', code + message + details);
                setLoading(false);
                setFoundError(true);
                setError(error);
            });
        }
    }

    const handleEmailSubmit = (e) => {
        setLoading(true);
        handleEmail(e);
    };

    return (
        <div style={{ overflow: 'hidden', scrollbarWidth: 'none', '-ms-overflow-style': 'none', '::-webkit-scrollbar': { display: 'none' } }}>

        <div style={{textAlign:'center'}} className='content-wrapper-landing'>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center', position: 'relative', height: '700px'}}>
                <video 
                    autoPlay 
                    loop 
                    muted 
                    playsInline 
                    webkit-playsinline="true"
                    preload="true"
                    poster={require("../Assets/main-home-video.png")}
                    style={{position: 'absolute', width: '100%', height: '100%', objectFit: 'cover', zIndex: -1}}
                    ref={(videoElement) => {
                        if (videoElement) {
                            videoElement.play().catch(error => {
                                console.error("Error attempting to play video:", error);
                            });
                        }
                    }}
                >
                    <source src={require("../Assets/main-home-video.mp4")} type="video/mp4" />
                </video>
                {/* <div className="main-home-video" style={{position: 'absolute', width: '100%', height: '100%', objectFit: 'fill', zIndex: -1, backgroundColor: 'rgba(0,0,0,0.5)'}}></div> */}
                <div style={{position: 'relative', zIndex: 1, width: '100%', height: '100%', textAlign:'center', backgroundColor: 'rgba(0,0,0,0.5)'}}>
                    {/* <div style={{ color:'white', textAlign: 'center', alignItems: 'center', marginTop: '0.5%', marginBottom: '0.5%'}}>
                        Shop for Life
                    </div> */}
                    {/* <div style={{width: '100%', height: '1px', backgroundColor: 'white', marginTop: '0.5%', marginBottom: '0.5%'}}></div> */}
                    <div className='mobile-navbar mobile-navbar-landing' onClick={() => window.location.reload()}>
                        <div className='mobile-navbar-logo'>
                            <div className='logo' style={{width:'140px', height:'60px'}}>
                                <img src={Logo} alt="Logo" className='logo-img' style={{ width: '100%', height: '100%', objectFit: 'contain', padding:'10%' }} />
                            </div>
                        </div>
                        <button onClick={() => navigate('/login')} style={{textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)', fontSize: '16px', color: 'white', background: 'transparent', border: 'none', borderRadius: '5px', cursor: 'pointer', fontFamily: 'Raleway', fontWeight: 'bold'}}>Log In</button>
                    </div>
                    <div className="carousel-wrapper" style={{justifyContent: 'center', alignItems: 'center'}}>
                        {/* <ScrollingImages images={imageGridArray} /> */}
                        <div className="overlay" style={{display:'flex', flexDirection:'column'}}>
                            <h2 style={{color: 'white', paddingTop: '8%', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}} className='title-landing'>TU ESTILO PERSONAL EN UNA APP</h2>
                            {/* <button className="join-button" onClick={() => setSigninModal(true)}>Join the Waitlist</button> */}
                            {!signupModal &&
                                <div className='input-wrapper-landing' style={{ width: '55%', height: '55px', position: 'relative', marginRight: '0px', marginTop: '4%'}}>
                                    <input
                                        type="text"
                                        placeholder={placeholder}
                                        className='search-bar-landing'
                                        onClick={() => setWaitlistModal(true)}
                                        readOnly
                                        autoComplete="off"
                                        autoCorrect="off"
                                        autoCapitalize="off"
                                        spellCheck="false"
                                        style={{ 
                                            fontFamily: 'Raleway', 
                                            fontWeight:'700',
                                            height: '100%', 
                                            padding: '10px',
                                            borderRadius: '15px', 
                                            paddingLeft: '50px',
                                            paddingRight: '20px',
                                            boxSizing: 'border-box',
                                            animation: 'fadeEffect 1.5s',
                                            cursor: 'pointer'
                                        }}
                                    />
                                    <div className="search-icon-landing" style={{ position: 'absolute', left: '20px', top: '50%', transform: 'translateY(-50%)'}}>
                                        <SearchIcon style={{ width: '20px', height: '20px' }} />
                                    </div>
                                </div>
                            }
                            {signupModal &&
                                <div style={{marginTop: '8%'}}>
                                    {code && <p style={{color:'white', fontSize:'14px', textAlign:'left'}}><i>Invite code: {code}</i></p>}
                                    <form onSubmit={handleEmailSubmit} className="join-form">
                                        <input 
                                            type="email" 
                                            placeholder="Tu email" 
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} 
                                            required 
                                            className="join-input"
                                        />
                                        {code ? <button type="submit" className="join-button">Aceptar invitación</button> : <button type="submit" className="join-button">Únite a la lista de espera</button>}
                                    </form>
                                    <p style={{color:"white"}}>{emailResult}</p>
                                    <p style={{color:"white"}}>{error.message}</p>
                                </div>
                            }
                            <h2 className='subtitle' style={{textAlign:'center', color:'white', marginTop:'8%', paddingBottom: '8%', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Compra a través de inteligencia artificial <br></br>las mejores marcas de Argentina </h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{textAlign:'left', marginTop:'2%', marginBottom: '7%'}}>
                <ScrollingLogos logos={brandLogos} />
            </div> */}
            {/* <div style={{textAlign:'left',display: 'flex', marginTop:'2%'}}>
                <h1 className='brands-title' style={{fontFamily: 'Raleway', fontWeight:'700' }}>About</h1>
            </div>
            <h3 className='about' style={{textAlign:'justify', margin: '4%'}}>Wearly is an AI-powered platform that aggregates every fashion brand and platform, simplifying the exploration of thousands of new and used listings in a unified experience.</h3> */}
            {/* <div style={{textAlign:'left',display: 'flex', marginTop:'2%'}}>
                <h1 className='brands-title' style={{fontFamily: 'Raleway', fontWeight:'700', color:'black' }}>How it works</h1>
            </div>
            <div style={{ textAlign: 'center', display: 'flex', marginTop: '5%', width:'100%', height:'100%'}}>
                <img src={tutorialImage} style={{width: '100%', height: '100%', objectFit: 'contain'}} alt="Tutorial" />
            </div> */}
            {/* <div style={{textAlign:'left',display: 'flex', marginTop:'2%'}}>
                <h1 className='brands-title' style={{fontFamily: 'Raleway', fontWeight:'700' }}>Features</h1>
            </div> */}

            {suggestedStyles && suggestedStyles.length > 0 && (
                <div style={{marginLeft: '30px'}}>
                    <h2 className='section-title' style={{color:'black', textAlign:'left', marginTop: '4%', fontFamily: 'Raleway', fontWeight: '900'}}>Compra por Estilos</h2>
                    <div className="styles-images" style={{ 
                        display: 'flex', 
                        overflowX: 'scroll',
                        overflowY: 'hidden', 
                        marginTop: '4%', 
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        }}>
                        {suggestedStyles.map((style, index) => {
                            const styleLowerCase = style.toLowerCase();
                            const profileImage = require(`../Assets/${styleLowerCase}-profile-image.png`);
                            return (
                                <div key={index} className="style-image" style={{ 
                                    position: 'relative',
                                    borderRadius: '50%',
                                    height: '180px', 
                                    width: '180px', 
                                    flex: '0 0 auto',
                                    marginRight: '10px',
                                    marginLeft: index === 0 ? '0' : '4%',
                                    cursor: 'pointer',
                                    }} onClick={() => setWaitlistModal(true)}>
                                    <img src={profileImage} alt={style} style={{
                                        borderRadius: '50%',
                                        height: '100%', 
                                        width: '100%', 
                                        objectFit: 'cover',
                                    }} />
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: '100%',
                                        width: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center',
                                        borderRadius: '50%',
                                    }}>
                                        <h3 style={{
                                            color:'white', 
                                            textAlign:'center', 
                                            fontFamily: 'Raleway', 
                                            fontWeight: '900',
                                            cursor: 'pointer',
                                            textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'
                                            }} onClick={() => setWaitlistModal(true)}>
                                            {style.toUpperCase()}
                                        </h3>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className="side-by-side-container">
                {/* {communityPosts && communityPosts.length > 0 && (
                    <div className='event-container' style={{display: 'inline-block', marginTop: '3%', marginLeft: '30px'}}>
                        <h2 className='section-title' style={{color:'black', marginBottom:'0%', textAlign:'left', fontFamily: 'Raleway', fontWeight: '900'}}>NO TE PODES PERDER ESTE EVENTAZO</h2>
                        <p style={{color:'black', textAlign:'left', marginBottom:'4%', marginTop: '2px', fontFamily: 'Raleway', fontWeight: '900', fontStyle: 'italic'}}>EL EVENTO DE MODA MAS GRANDE DE ARGENTINA</p>
                        <div className="side-by-side-container-content">
                            <div className="community-post">
                                <img onClick={() => window.open('https://www.instagram.com/pilchaargentina/', '_blank')} style={{ height:'100%', width: '440px', cursor: 'pointer' }} src={pilchaEventTickets} alt="Pilcha Event Tickets" className="community-post-img" />
                            </div>
                        </div>
                    </div>
                )} */}
                {newRecomendedItems && newRecomendedItems.length > 0 && (
                    <div className='new-recomended-items-container' style={{marginTop: '3%', marginLeft: '30px'}}>
                        <div className="section-title-container">
                            <h2 className='section-title' style={{color:'black', marginBottom:'0%', textAlign:'left', fontFamily: 'Raleway', fontWeight: '900'}}>Nueva Ropa en Wearly</h2>
                            <p style={{color:'black', textAlign:'left', marginBottom:'4%', marginTop: '2px', fontFamily: 'Raleway', fontWeight: '900', fontStyle: 'italic'}}>No te podes perder estos lanzamientos</p>
                        </div>
                        <div className='new-recomended-items-container-items'>
                        {newRecomendedItems.map((item, index) => (
                            item.item_image != null && (
                                <div key={index} className="suggestion-item" onClick={() => setWaitlistModal(true)}>
                                    <img src={item.item_image} alt={item.item_name} className='suggestion-item-img' />
                                </div>
                            )
                        ))}
                        </div>
                    </div>
                )}
            </div>

            <div style={{marginLeft: '30px', marginRight: '30px'}}>
                <h2 className='section-title' style={{color:'black', textAlign:'left', marginTop: '4%', fontFamily: 'Raleway', fontWeight: '900', marginBottom: '20px'}}>Popping en Argentina</h2>
                <div className='side-by-side-img-rectangles' style={{display: 'flex', justifyContent: 'space-between', gap: '40px'}}>
                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}} onClick={() => setWaitlistModal(true)}>
                        <img src={require('../Assets/streetwear-argentina.webp')} alt='Rectangle 1' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <h1 style={{color: 'white', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Streetwear en Argentina</h1>
                        </div>
                    </div>
                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}} onClick={() => setWaitlistModal(true)}>
                        <img src={require('../Assets/baggy-pants.webp')} alt='Rectangle 2' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <h1 style={{color: 'white', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Los Mejores Baggy Fit de Argentina</h1>
                        </div>
                    </div>
                </div>
                <div className='side-by-side-img-rectangles' style={{display: 'flex', justifyContent: 'space-between', gap: '40px', marginTop: '40px'}}>
                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}} onClick={() => setWaitlistModal(true)}>
                        <img src={require('../Assets/vestite-como-famoso.webp')} alt='Rectangle 1' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <h1 style={{color: 'white', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Vestite como una Celebridad</h1>
                        </div>
                    </div>
                    <div className='inner-side-by-side' style={{position: 'relative', height: '258px', width: 'calc(50% - 30px)', borderRadius: '20px', cursor: 'pointer'}} onClick={() => setWaitlistModal(true)}>
                        <img src={require('../Assets/shop-preview.webp')} alt='Rectangle 2' style={{height: '100%', width: '100%', borderRadius: '20px', objectFit: 'cover'}} />
                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <h1 style={{color: 'white', fontWeight: 'bold', textShadow: '0 0 15.4px rgba(255, 255, 255, 0.6)'}}>Encontra las Mejores Marcas</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{marginTop: '40px', marginLeft: '30px', marginRight: '30px'}}>
                <button 
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '15px',
                        fontSize: 'clamp(14px, 2vw, 16px)',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontFamily: 'Raleway',
                        width: '100%',
                        height: '60px',
                        fontStyle: 'italic',
                        textDecoration: 'underline',
                    }}
                    onClick={() => window.open('https://brands.wearlyapp.com', '_blank')}
                >
                    Soy una marca y quiero vender en Wearly
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 24 24" 
                        fill="white" 
                        width="24px" 
                        height="24px" 
                        style={{marginLeft: '10px'}}
                    >
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/>
                    </svg>
                </button>
            </div>
            <div style={{marginTop:'2%'}}>
                <form onSubmit={handleEmailSubmit} className="join-form" style={{marginTop:'6%'}}>
                    <input 
                        type="email" 
                        placeholder="Tu email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                        className="join-input"
                        style={{borderRadius: '0px', borderBottom: '1px solid black'}}
                    />
                    {code ? <button type="submit" className="join-button">Aceptar invitación</button> : <button type="submit" className="join-button">Únite a la lista de espera</button>}
                </form>
                <footer className="footer footer-landing" style={{margin: '0px', padding: '20px', textAlign: 'left'}}>
                    <div className="footer-content footer-content-landing">
                        <div className="footer-logo footer-logo-landing">
                            <img src={LogoBlack} alt="Wearly Logo" style={{height: '60px'}} />
                        </div>
                        <ul className="footer-links footer-links-landing">
                            <li><a href="/">Home</a></li>
                            <li><a href="/terms">Términos y Condiciones</a></li>
                            <li><a href="/privacy">Política de Privacidad</a></li>
                            <li><a href="https://wearly-tech.notion.site/Wearly-FAQ-44912421f4684846b815004e31931923?pvs=74">FAQ</a></li>
                            <li><a href="mailto:contactwearly@gmail.com">Soporte</a></li>
                            <li><a href="https://brands.wearlyapp.com">Soy una marca</a></li>
                        </ul>
                        <div className="footer-social footer-social-landing">
                            <a href="https://www.instagram.com/wearlyapp" target="_blank" rel="noopener noreferrer">
                                <InstagramIcon style={{width: '24px', height: '24px', marginRight: '10px'}} />
                            </a>
                            <a href="https://www.tiktok.com/@wearlyapp" target="_blank" rel="noopener noreferrer">
                                <TikTokIcon style={{width: '24px', height: '24px', marginRight: '10px'}} />
                            </a>
                            <a href="https://x.com/wearlyapp" target="_blank" rel="noopener noreferrer">
                                <TwitterIcon style={{width: '24px', height: '24px', marginRight: '10px'}} />
                            </a>
                            <a href="https://www.linkedin.com/company/wearlyai" target="_blank" rel="noopener noreferrer">
                                <LinkedInIcon style={{width: '24px', height: '24px'}} />
                            </a>
                        </div>
                    </div>
                </footer>
                <div className="copyright">
                    <p>&copy; {new Date().getFullYear()} Wearly Technologies SAS. All rights reserved.</p>
                </div>
            </div>
            {waitlistModal &&
                <div className='modal no-scroll' style={{borderRadius: '10px'}}>
                    <div className='modal-content' style={{display: 'flex', flexDirection: 'row-reverse', borderRadius: '10px', paddingBottom: '40px'}}>
                        <button className='modal-close-btn-landing' onClick={() => setWaitlistModal(false)} style={{marginLeft: 'auto'}}>X</button>
                        <div style={{flexGrow: 1}}>
                            <div className='modal-header'>
                                <h2 style={{textAlign:'left'}}>Unite a la lista de espera</h2>
                            </div>
                            <form onSubmit={handleEmailSubmit} className="modal-body">
                                <p style={{textAlign:'left'}}>Te notificaremos cuando salga de la lista de espera.</p>
                                <input type="email" placeholder="Tu email" value={email} onChange={(e) => setEmail(e.target.value)} required className="join-input" style={{borderRadius: '5px', marginTop: '20px', marginBottom: '40px'}} />
                                {code ? <button type="submit" className="join-button">Aceptar invitación</button> : <button type="submit" className="join-button">Únite a la lista de espera</button>}
                            </form>
                        </div>
                        <button className='modal-close-btn-landing' style={{backgroundColor: 'transparent', border: '0px', marginLeft: 'auto', boxSizing: 'border-box', cursor: 'pointer', textAlign: 'left'}}></button>
                    </div>
                </div>
            }
            {feedbackAsk && 
                <div className='modal no-scroll'>
                    <div className='modal-content'>
                        <button className='modal-close-btn' onClick={() => setFeedbackAsk(false)}>X</button>
                        <div className='modal-confetti'>
                            <ConfettiExplosion />
                        </div>
                        <div className='modal-header'>
                            <h2>Gracias por unirte a nuestra lista de espera! 🥳</h2>
                        </div>
                        <div className='modal-body'>
                            <p style={{fontSize:'14px'}}>Te notificarmenos cuando salgas de la lista de espera.</p>
                            <h1 style={{textAlign:'center'}}>
                                {isWaitlistPositionLoading ? (
                                    <div>Loading...</div> // Show loading message or spinner
                                ) : (
                                    `#${userWaitlistPosition}` // Show waitlist position when not loading
                                )}
                            </h1>
                            <p style={{fontSize:'14px'}}>Para avanzar en la lista de espera, puedes compartir Wearly con el siguiente enlace con cualquier persona que conozcas y que pueda estar interesada.</p>
                            <div style={{marginTop:'5%'}}>
                                Compartir Wearly
                                <button
                                    type="button"
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: 'transparent',
                                        border: '0px',
                                        boxSizing: 'border-box',
                                        fill: 'black',
                                    }}
                                    onClick={() => handleRefShare(email)}
                                >
                                    <ShareIcon style={{ width: '20px', height: '20px' }} />
                                </button>
                            </div>
                            <br/>
                            <a className='share-url' href={`https://wearlyapp.com/?ref=${email}`} target="_blank" rel='noreferrer' style={{marginTop:'5%'}}><i>https://wearlyapp.com/?ref={email}</i></a>
                            <form onSubmit={handleFeedback} style={{marginTop:'7%'}}>
                                <p style={{fontSize:'14px'}}><i>Nos encantaría conocerte mas!</i></p>
                                <input type="text" placeholder="Marca Favorita" />
                                <input type="text" placeholder="¿Por qué te interesa Wearly?" />
                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            }
            </div>
            {loading && 
                <div className="loading-overlay" style={{ zIndex: 9999 }}>
                    <div className="spinner"></div>
                </div>
            }
        </div>
    );
}

export default Landing;